<template>
  <div>
    <div class="__bg">
      <img src="../../assets/img/recommends/bg.png" alt="" />
    </div>
    <div class="__content">
      <div class="_type_title">
        <b>“{{ words }}”</b><span>的搜索结果</span>
      </div>
      <ArticleList :words="words" :isActived="true" />
    </div>
  </div>
</template>
<script>
import ArticleList from "./article_list";
export default {
  name: "typelist",
  data() {
    return {
      words: "",
    };
  },
  components: {
    ArticleList,
  },
  created() {
    this.words = this.$route.query.keywords ? this.$route.query.keywords : 0;
  },
};
</script>
<style lang="scss" scoped>
.__bg {
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  height: 332px;
  z-index: 2;
  img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}
.__content {
  position: relative;
  z-index: 3;
}

._type_title {
  width: 1300px;
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  line-height: 24px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 60px 0 37px;
  b {
    font-size: 18px;
    font-weight: bold;
  }
  span {
    display: inline;
    font-size: 14px;
  }
}
</style>
